.image-gallery {
  width: 100% !important;
  height: 181px;

  &,
  .image-gallery-content,
  .image-gallery-swipe,
  .image-gallery-slide,
  .image-gallery-slide-wrapper {
    height: 100%;
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    padding: 20px 10px;
  }

  .image-gallery-svg {
    max-height: 60px;
    max-width: 40px;

    @include screen-sm-min {
      max-height: 40px;
      max-width: 20px;
    }
  }

  .image-gallery-thumbnail {
    border: 2px solid white;
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 8px;
  }

  .image-gallery-thumbnails {
    position: relative;
    overflow: initial;
    z-index: 2;
  }

  .image-gallery-thumbnails-container {
    margin-top: -40px;
  }

  .image-gallery-slides {
    height: 100%;
    z-index: 2;
    border-radius: 0;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    height: 100%;
    cursor: default;
  }

  .image-gallery-index {
    top: auto;
    bottom: 16px;
    left: 16px;
    width: fit-content;
  }

  .apartment-details-gallery {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      height: 390px !important;
    }

    @include screen-xl-max {
      .image-gallery-index {
        bottom: 50px;
      }
    }
  }

  &__apartments-gellery-wrapper {
    min-height: 430px;
  }
}

;@import "sass-embedded-legacy-load-done:107";