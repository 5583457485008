.endless-holiday {
  &__accordion {
    & + & {
      margin-top: 10px;
    }

    .accordion-button {
      box-shadow: none;
      background-color: $light-blue;
      transition: background-color 300ms linear;

      &:not(.collapsed) {
        background-color: white;
        color: initial;
      }
    }

    .accordion-item {
      &.is-gold {
        border: 2px solid $subscription-gold;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(212, 160, 69, 0.05);
        }
      }

      &.is-silver {
        border: 2px solid $subscription-silver;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(131, 137, 142, 0.05);
        }
      }

      &.is-brown {
        border: 2px solid $subscription-brown;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(133, 91, 19, 0.05);
        }
      }
    }

    .no-toggle {
      .accordion-button::after {
        display: none;
      }

      .accordion-button:not(.collapsed)::after {
        display: none;
      }
    }

    .spin-input__container {
      @include screen-md-max {
        max-width: 100%;
      }
    }
  }

  &__voucher-selection {
    margin-left: 67px;
  }

  &__description-modal {
    &__close-button {
      z-index: 1;
      border-top-right-radius: 4px;

      @include screen-md-max {
        background-color: white !important;

        .uil-times {
          font-size: 20px;
          color: $darker-gray !important;
        }
      }
    }

    &__image {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: calc(100% + 53px);
      position: absolute;
      top: -53px;
      right: 0;

      @include screen-md-max {
        position: relative;
        top: 0;
        max-height: 420px;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &__offer-counter {
    margin-top: 10px;
    border: 1px solid $danger;
    border-radius: 4px;
    padding: 10px;

    .icon-with-text {
      justify-content: center;

      &__text {
        color: $danger;
        text-align: center;
        display: block;
        font-size: $font-xl;
      }

      &__icon {
        font-size: $font-xl;
      }
    }

    .spinner-border-sm {
      color: $danger !important;
    }
  }

  @mixin confirmationProductCard($color) {
    background-color: rgba($color, 0.05);
    border-color: $color;

    .endless-holiday__confirmation__product-card__content__title {
      color: $color;
    }
  }

  &__confirmation {
    &__product-timeline {
      &__point {
        background-color: rgba(216, 216, 216, 0.4);
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 1;
      }

      &__line {
        border-left: 1px dashed rgba(216, 216, 216);
        height: calc(100% - 35px);
        width: 2px;
        position: absolute;
        top: 35px;
        left: 10px;
      }
    }

    &__product-card {
      margin-left: 15px;
      border-style: solid;
      border-width: 2px;

      .endless-holiday__confirmation__product-card__image {
        object-fit: cover;
      }

      &.is-gold {
        @include confirmationProductCard($subscription-gold);
      }

      &.is-silver {
        @include confirmationProductCard($subscription-silver);
      }

      &.is-brown {
        @include confirmationProductCard($subscription-brown);
      }

      &.is-gastro-card {
        @include confirmationProductCard($darker-gray);

        .endless-holiday__confirmation__product-card__image {
          height: auto;

          @include screen-xl-min {
            height: 87px;
          }
        }
      }
    }
  }
}
.endless-holiday__hero-image {
  object-position: bottom;
}

;@import "sass-embedded-legacy-load-done:129";