$bocian-primary: #023672;
$bocian-secondary: #e1e9f5;

.bocian-voucher {
  &__wrapper * {
    font-family: 'Roboto', sans-serif !important;
  }

  &__form {
    &__card {
      border-radius: 20px;
      padding: 30px;
      position: relative;
      z-index: 2;

      @include screen-md-max {
        padding-left: 10px;
        padding-right: 20px;
        max-width: calc(100% - 20px);
        margin: 0 auto;
      }

      &:before {
        content: '';
        position: absolute;
        right: -10px;
        top: 10px;
        width: 60%;
        height: 100%;
        background-color: $bocian-primary;
        border-radius: 20px;
        z-index: -1;

        @include screen-md-max {
          right: 0;
          width: 70%;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $bocian-secondary;
        border-radius: 20px;
        z-index: -1;

        @include screen-md-max {
          width: calc(100% - 10px);
        }
      }
    }

    &__agreements {
      label {
        color: $bocian-primary !important;
      }
    }

    &__submit {
      background-color: $bocian-primary;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 8px 20px;
      cursor: pointer;
      transition: background-color 0.3s;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: start;

      @include screen-md-max {
        justify-content: center;
      }

      &:hover {
        background-color: darken($bocian-primary, 10%);
      }
    }
  }

  &__gallery {
    max-width: 100% !important;
    padding: 0;

    .package-wholesale__resort {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08);
    }

    .package-wholesale__resorts {
      padding: 0;
      margin-top: 50px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      width: 100%;

      &__title {
        color: $bocian-primary;
        text-align: center;
      }

      .react-multi-carousel-dot {
        & > button {
          background-color: $darker-gray;
          opacity: 50%;
        }

        &--active > button {
          background-color: $darker-gray;
          opacity: 100%;
        }
      }
    }
  }

  &__last-minute-section {
    background-color: $bocian-secondary;
    padding: 30px;
    border-radius: 20px;
    overflow: hidden;

    .booking-offers__card__season-signature__wrapper {
      top: 10px;
      left: 10px;
    }

    .booking-offers__card__image {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    @include screen-md-max {
      padding-inline: 10px;
    }
  }
}

.bocian-text-primary {
  color: $bocian-primary !important;
}

;@import "sass-embedded-legacy-load-done:127";